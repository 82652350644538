import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Redirect, useLocation } from "react-router-dom";

import AceEditor from "react-ace";
import Countdown, { zeroPad } from "react-countdown";
import unescapeJs from "unescape-js";
import Loading from "../loading/Loading";
import Axios from "axios";
import Swal from "sweetalert2";

import "./Compiler.css";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/ext-language_tools";
import DialogSubmit from "../../components/submit/DialogSubmit";

import ReactGA from "react-ga";

const useStyles = makeStyles({
  runIcon: {
    "&:hover": {
      transform: "translateX(7px)",
      transition: "0.3s",
    },
  },
  finishIcon: {
    "&:hover": {
      transform: "translateY(-3px)",
      transition: "0.3s",
    },
  },
  buttonCancelFinish: {
    backgroundColor: "#fff",
    color: "#2f50ad",
    textTransform: "none",
    fontWeight: "500",
    borderRadius: "50px",
    padding: "5px 35px",
    marginLeft: "6px",
    outline: "none !important",
  },
  buttonFinish: {
    backgroundColor: "#2f50ad",
    color: "#fff",
    textTransform: "none",
    fontWeight: "500",
    borderRadius: "50px",
    padding: "5px 35px",
    outline: "none !important",
    "&:hover": {
      backgroundColor: "#2f50ad",
      color: "#fff",
    },
  },
  countdown: {
    fontWeight: "bold",
    border: "1px solid",
    padding: "5px",
    backgroundColor: "#fff",
    color: "#3f51b5",
    borderRadius: "5px",
  },
});

const NewCompiler = () => {
  // const SERVER = "http://168.63.249.48";
  const SERVER = "https://engine.coding.id/";
  const SERVER_RESULT =
    "http://ndsrndmain.southeastasia.cloudapp.azure.com/NawaEventAPI";

  const { dataAssessment, listQuestion, dataMember, timer, startTest } =
    useLocation().state;

  // console.log(dataAssessment)

  const classes = useStyles();
  const [currentProblem, setCurrentProblem] = useState(1);
  // identity untuk mengenali language di backend
  const [languageId, setLanguageId] = useState(
    listQuestion[currentProblem - 1].language[0].detail_language.editor_code
  );
  // identity untuk mengenali bahasa di ace editor
  const [languageCode, setLanguageCode] = useState(
    listQuestion[currentProblem - 1].language[0].detail_language.language_code
  );
  const [input, setInput] = useState(
    unescapeJs(listQuestion[0].language[0].question_task)
  );
  const [userInput, setUserInput] = useState(
    unescapeJs(listQuestion[0].data_question.question_input)
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogFinish, setDialogFinish] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [assessmentScore, setAssessmentScore] = useState([]);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(null);
  // const [timeLeft, setTimeLeft] = useState(Date.now() + (3 * 60000))
  const [timeLeft, setTimeLeft] = useState(startTest + Number(timer) * 60000);

  const [scoreObject, setScoreObject] = useState({
    arrayQuestion: [],
    unsolvedQuestion: 0,
    totalQuestion: 0,
  });

  useEffect(() => {
    ReactGA.pageview("/test-assessment");
  }, []);

  useEffect(() => {
    localStorage.getItem(
      `${
        dataAssessment.assessment_code + dataMember.id
      }_${currentProblem}_${languageCode}`
    )
      ? setInput(
          localStorage.getItem(
            `${
              dataAssessment.assessment_code + dataMember.id
            }_${currentProblem}_${languageCode}`
          )
        )
      : unescapeJs(listQuestion[0].language[0].question_task);
  }, []);

  useEffect(() => {
    //console.log(JSON.stringify(localStorage.getItem(`results`)) + "results");
    if (
      localStorage.getItem(
        `${dataAssessment.assessment_code + dataMember.id}_results`
      )
    ) {
      let results = localStorage.getItem(
        `${dataAssessment.assessment_code + dataMember.id}_results`
      );
      setAssessmentScore(JSON.parse(results));
    }
  }, []);

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // useEffect(() => {
  //   console.log(assessmentScore);
  // }, [assessmentScore, setAssessmentScore]);

  const SubmitAnswer = async (result, Total, Score) => {
    // console.log(result);
    // console.log(Total);
    // console.log(Score);

    // console.log("save result");
    if (assessmentScore[currentProblem] == null) {
      setAssessmentScore({
        ...assessmentScore,
        [currentProblem]: {
          question_code: listQuestion[currentProblem - 1].question_code,
          score: Score == undefined ? 0 : Score,
          input: input,
          language_code: languageCode,
        },
      });
    } else {
      setAssessmentScore({
        ...assessmentScore,
        [currentProblem]: {
          question_code: listQuestion[currentProblem - 1].question_code,
          score: Score == undefined ? 0 : Score,
          input: input,
          language_code: languageCode,
        },
      });
    }

    localStorage.setItem(
      `${dataAssessment.assessment_code + dataMember.id}_results`,
      JSON.stringify({
        ...assessmentScore,
        [currentProblem]: {
          question_code: listQuestion[currentProblem - 1].question_code,
          score: Score == undefined ? 0 : Score,
          input: input,
          language_code: languageCode,
        },
      })
    );

    // else if (assessmentScore[currentProblem].score < Score) {
    //   setAssessmentScore({
    //     ...assessmentScore,
    //     [currentProblem]: {
    //       question_code: listQuestion[currentProblem - 1].question_code,
    //       score: Score == undefined ? 0 : Score,
    //       input: input,
    //       language_code: languageCode,
    //     },
    //   });
    // }

    setIsSubmit(false);
    const response = fetch(SERVER_RESULT + "/api/Submission/Submit", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        User: localStorage.getItem("member_name"),
        Problem: currentProblem,
        Language: languageId,
        Code: input,
        Result: JSON.stringify(result),
        Total: Total,
      }),
    })
      .then((res) => {
        // setIsSubmit(false)
        //console.log(res);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const finishHandler = () => {
    setDialogFinish(false);
    setLoading(true);
    let arrayResult = Object.values(assessmentScore).flat();

    let hasil = 0;
    arrayResult.map((x) => (hasil += x.score));

    const dataResult = {
      assessment_code: dataAssessment.assessment_code,
      member_id: dataMember.id,
      hasil: hasil,
      answer: arrayResult,
    };

    Axios.post(
      `${process.env.REACT_APP_API_END_POINT}/assessment/submitresult`,
      dataResult
    )
      .then((res) => {
        //localStorage.clear();
        // localStorage.removeItem(
        //   `${dataAssessment.assessment_code}_${currentProblem}_${languageCode}_results`
        // );

        //remove local storage with same assessment code
        let idInString = String(dataMember.id);
        Object.entries(localStorage)
          .map((x) => x[0])
          .filter(
            (x) =>
              x.substring(
                0,
                dataAssessment.assessment_code.length + idInString.length
              ) == `${dataAssessment.assessment_code + dataMember.id}`
          )
          .map((x) => localStorage.removeItem(x));

        setLoading(false);
        setRedirect({ pathname: "/finish" });
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);

        if (err.response) {
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          Swal.fire({
            text: err.response.data.error,
            icon: "error",
            showCloseButton: true,
          });
        } else {
          Swal.fire({
            text: "something was wrong",
            icon: "error",
            showCloseButton: true,
          });
        }
      });
  };

  const getResultHandler = () => {
    {
      let result = Object.values(assessmentScore).flat();
      if (result.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Its look like your answer not passing test case!",
        });
      } else {
        let resultScore = "";
        result.forEach((item) => {
          resultScore += `Score question ${item.question_code} = ${item.score} (${item.language_code})<br>`;
        });
        Swal.fire({
          title: "Assessment Score",
          // html: `${resultScore} <br><hr><small>Note : recorded answer is the highest score from your input</small>`,
          html: `${resultScore} <br><hr><small>Note : recorded answer is the latest score from your input</small>`,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok!',
          confirmButtonAriaLabel: "Thumbs up, great!",
        });
      }
    }
  };

  const showFinalScore = () => {
    let result = Object.values(assessmentScore).flat();
    if (result.length === 0) {
      //listQuestion[currentProblem - 1].language[0].detail_language.editor_code
      let resultScore = "";
      listQuestion.forEach((item, index) => {
        console.log(item);
        resultScore += `${index + 1}. ${item.question_code} - ${
          item.data_question.question_name
        } = 0 <br />`;
      });
      return <div dangerouslySetInnerHTML={{ __html: resultScore }} />;
      // return (
      //   <span style={{ color: "#2e2e2e" }}>
      //     <span style={{ color: "red", fontWeight: "bold" }}>
      //       Click "RUN" to recalculate your code before you submit
      //     </span>
      //     <br />
      //     Your current score : 0 <br />{" "}
      //   </span>
      // );
    } else {
      let resultScore =
        "<span style='color: red; font-weight: " +
        "bold" +
        "'>Click 'RUN' to recalculate your code before you submit </span> <br /> Current Score : <br/>";
      result.forEach((item) => {
        resultScore += `Score question ${item.question_code} = ${item.score} (${item.language_code}) <br />`;
      });
      return <div dangerouslySetInnerHTML={{ __html: resultScore }} />;
    }
  };

  const changeInputHandler = (newValue) => {
    setInput(newValue);
    // Save Code to Storage
    localStorage.setItem(
      `${
        dataAssessment.assessment_code + dataMember.id
      }_${currentProblem}_${languageCode}`,
      newValue
    );
  };

  const changeLanguageHandler = (event) => {
    event.preventDefault();
    setLanguageId(event.target.value);
    let languageObject = listQuestion[currentProblem - 1].language.find(
      (x) => x.detail_language.editor_code === event.target.value
    );

    // console.log(languageObject)

    setLanguageCode(languageObject.detail_language.language_code);
    let questionTask = unescapeJs(languageObject.question_task);
    setInput(
      localStorage.getItem(
        `${dataAssessment.assessment_code + dataMember.id}_${currentProblem}_${
          languageObject.detail_language.language_code
        }`
      ) || questionTask
    );
  };

  const changeProblemHandler = (event) => {
    event.preventDefault();
    setCurrentProblem(event.target.value);

    let questionInput = unescapeJs(
      listQuestion[event.target.value - 1].data_question.question_input
    );
    setUserInput(questionInput);

    if (
      localStorage.getItem(
        `${dataAssessment.assessment_code + dataMember.id}_${
          event.target.value
        }_${languageCode}`
      )
    ) {
      setInput(
        localStorage.getItem(
          `${dataAssessment.assessment_code + dataMember.id}_${
            event.target.value
          }_${languageCode}`
        )
      );
    } else {
      let languageObject = listQuestion[event.target.value - 1].language.find(
        (x) => x.language_code === languageCode
      );

      if (languageObject === undefined) {
        let questionObject = listQuestion[event.target.value - 1].language[0];

        setLanguageId(questionObject.detail_language.editor_code);
        setLanguageCode(questionObject.detail_language.language_code);

        let questionTask = unescapeJs(questionObject.question_task);
        setInput(questionTask);
      } else {
        let questionTask = unescapeJs(languageObject.question_task);
        setInput(questionTask);
      }
    }
  };

  const TestAll = async (index, Result, Total, Score) => {
    let outputText = document.getElementById("output");
    let item = listQuestion[currentProblem - 1].test_case[index];
    const response = await fetch(SERVER + "/submissions", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        source_code: input,
        stdin: unescapeJs(item.input),
        language_id: languageId,
        cpu_time_limit: 10,
        cpu_extra_time: 5,
        wall_time_limit: 20,
      }),
    });

    outputText.innerHTML += `\nTest Case ${index + 1} : `;

    const jsonResponse = await response.json();

    let jsonGetSolution = {
      status: { description: "Queue" },
      stderr: null,
      compile_output: null,
    };

    while (
      (jsonGetSolution.status.description == "Queue" ||
        jsonGetSolution.status.description == "Processing") &&
      jsonGetSolution.stderr == null &&
      jsonGetSolution.compile_output == null
    ) {
      await sleep(1000);
      if (jsonResponse.token) {
        let url =
          SERVER + `/submissions/${jsonResponse.token}?base64_encoded=true`;

        const getSolution = await fetch(url, {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
        });

        jsonGetSolution = await getSolution.json();
      }
    }
    if (jsonGetSolution.stdout) {
      const output = atob(jsonGetSolution.stdout);

      if (output.trim() == unescapeJs(item.output).trim()) {
        outputText.innerHTML += `Success`;
        Result.push("Success");
        if (listQuestion[currentProblem - 1].test_case.length > index + 1) {
          TestAll(index + 1, Result, Total + 1, Score + parseInt(item.score));
        } else {
          SubmitAnswer(Result, Total + 1, Score + parseInt(item.score));
        }
      } else {
        outputText.innerHTML += `Failed`;
        Result.push("Failed");
        if (listQuestion[currentProblem - 1].test_case.length > index + 1) {
          TestAll(index + 1, Result, Total, Score);
        } else {
          SubmitAnswer(Result, Total, Score);
        }
      }
    } else if (jsonGetSolution.stderr) {
      const error = atob(jsonGetSolution.stderr);

      outputText.innerHTML += `Error: ${error} `;
      Result.push("Failed");
      if (listQuestion[currentProblem - 1].test_case.length > index + 1) {
        TestAll(index + 1, Result, Total, Score);
      } else {
        SubmitAnswer(Result, Total, Score);
      }
    } else if (
      jsonGetSolution.status.description !== "Accepted" &&
      jsonGetSolution.compile_output == null
    ) {
      const status = jsonGetSolution.status.description;

      outputText.innerHTML += `Error: ${status} `;
      Result.push("Failed");
      if (listQuestion[currentProblem - 1].test_case.length > index + 1) {
        TestAll(index + 1, Result, Total, Score);
      } else {
        SubmitAnswer(Result, Total, Score);
      }
    } else {
      const compilation_error = atob(jsonGetSolution.compile_output);

      outputText.innerHTML += `Error: ${compilation_error} `;
      Result.push("Failed");
      if (listQuestion[currentProblem - 1].test_case.length > index + 1) {
        TestAll(index + 1, Result, Total, Score);
      } else {
        SubmitAnswer(Result, Total, Score);
      }
    }
    // setIsSubmit(false)
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    // console.log(input)
    let outputText = document.getElementById("output");
    outputText.innerHTML = "";
    outputText.innerHTML += "Creating Submission ...\n";
    const response = await fetch(SERVER + "/submissions", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        source_code: input,
        stdin: userInput,
        language_id: languageId,
        cpu_time_limit: 10,
        cpu_extra_time: 5,
        wall_time_limit: 20,
      }),
    }).catch((err) => {
      //console.log(err);
    });
    outputText.innerHTML += "Submission Created ...\n";
    const jsonResponse = await response.json();

    let jsonGetSolution = {
      status: { description: "Queue" },
      stderr: null,
      compile_output: null,
    };

    while (
      (jsonGetSolution.status.description == "Queue" ||
        jsonGetSolution.status.description == "Processing") &&
      jsonGetSolution.stderr == null &&
      jsonGetSolution.compile_output == null
    ) {
      await sleep(1000);
      outputText.innerHTML = `Creating Submission ... \nSubmission Created ...\nChecking Submission Status\nstatus : ${jsonGetSolution.status.description}`;
      if (jsonResponse.token) {
        let url =
          SERVER + `/submissions/${jsonResponse.token}?base64_encoded=true`;

        const getSolution = await fetch(url, {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
        });

        jsonGetSolution = await getSolution.json();
        setIsSubmit(false);
      }
    }
    if (jsonGetSolution.stdout) {
      const output = atob(jsonGetSolution.stdout);
      outputText.innerHTML = "";
      if (
        output.trim() ==
        unescapeJs(
          listQuestion[currentProblem - 1].data_question.question_output
        ).trim()
      ) {
        outputText.innerHTML += "Result With User Input : Success\n";
        outputText.innerHTML += "=============================\n";
        outputText.innerHTML += `Your Output: ${output} \n\n`;
        outputText.innerHTML += `Expected Output: ${unescapeJs(
          listQuestion[currentProblem - 1].data_question.question_output
        )} \n\n`;
        TestAll(0, [], 0, 0);
      } else {
        outputText.innerHTML += `Result With User Input: Failed.\n===================== \n Your Output: ${output} \n\nExpected Output: ${unescapeJs(
          listQuestion[currentProblem - 1].data_question.question_output
        )} `;
        setIsSubmit(false);
        SubmitAnswer("FAILED", 0);
      }
    } else if (jsonGetSolution.stderr) {
      const error = atob(jsonGetSolution.stderr);

      outputText.innerHTML = "";

      outputText.innerHTML += `\n Error: ${error} `;
      setIsSubmit(false);
      SubmitAnswer("FAILED", 0);
    } else if (
      jsonGetSolution.status.description !== "Accepted" &&
      jsonGetSolution.compile_output == null
    ) {
      const status = jsonGetSolution.status.description;

      outputText.innerHTML = "";

      outputText.innerHTML += `\n Error: ${status} `;
      setIsSubmit(false);
      SubmitAnswer("FAILED", 0);
    } else {
      const compilation_error = atob(jsonGetSolution.compile_output);

      outputText.innerHTML = "";

      outputText.innerHTML += `\n Error: ${compilation_error} `;
      setIsSubmit(false);
      SubmitAnswer("FAILED", 0);
    }
    setIsSubmit(false);
  };

  const rendererTime = ({ days, hours, minutes, seconds, completed }) => {
    // console.log(startTest);
    // console.log(timer);
    // console.log(timeLeft);
    // console.log(days, hours, minutes, seconds, completed);
    if (days == 0 && hours == 0 && minutes == 5 && seconds == "00") {
      Swal.fire("Times almost up", "Please check again your answer", "info");
    }
    if (completed) {
      return <span className={classes.countdown}>Times Up!</span>;
    } else {
      if (days > 0) {
        return (
          <span className={classes.countdown}>
            Remaining Time = {zeroPad(days * 24 + hours)}:{zeroPad(minutes)}:
            {zeroPad(seconds)}
          </span>
        );
      } else {
        return (
          <span className={classes.countdown}>
            Remaining Time = {zeroPad(hours)}:{zeroPad(minutes)}:
            {zeroPad(seconds)}
          </span>
        );
      }
    }
  };

  const completeHandler = ({ completed }) => {
    if (completed) {
      finishHandler();
    }
  };

  const dialogOpenHandler = () => {
    setDialogOpen(true);
  };

  const dialogCloseHandler = () => {
    setDialogOpen(false);
  };

  const dialogFinishOpen = () => {
    let result = Object.values(assessmentScore).flat();
    console.log(listQuestion);
    if (result.length == 0) {
      let tempScore = [];

      listQuestion.forEach((item, index) => {
        let maxScore = 0;

        for (let i = 0; i < item.test_case.length; i++) {
          maxScore += Number(item.test_case[i].score);
        }

        let question = {
          question_code: item.question_code,
          question_name: item.data_question.question_name,
          result: 0,
          maxScore: maxScore,
        };

        tempScore.push(question);
      });

      setScoreObject({
        arrayQuestion: [...tempScore],
        unsolvedQuestion: listQuestion.length,
        totalQuestion: listQuestion.length,
      });
    } else {
      let tempScore = [];
      let solvedQuestion = 0;
      listQuestion.forEach((item, index) => {
        let maxScore = 0;

        for (let i = 0; i < item.test_case.length; i++) {
          maxScore += Number(item.test_case[i].score);
        }
        let question = {
          question_code: item.question_code,
          question_name: item.data_question.question_name,
          result: 0,
          maxScore: maxScore,
        };

        result.forEach((item) => {
          // resultScore += `Score question ${item.question_code} = ${item.score} (${item.language_code}) <br />`;
          if (item.question_code == question.question_code) {
            question.result = item.score;
            if (item.score > 0) {
              solvedQuestion++;
            }
          }
        });

        tempScore.push(question);
      });

      setScoreObject({
        arrayQuestion: [...tempScore],
        unsolvedQuestion: listQuestion.length - solvedQuestion,
        totalQuestion: listQuestion.length,
      });
    }
    setDialogFinish(true);
  };

  const dialogFinishClose = () => {
    console.log("Close dialog finish");
    setDialogFinish(false);
  };

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to={redirect} />;
    }
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        {renderRedirect()}
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-info justify-content-between">
          <a className="navbar-brand" href="">
            Hello, {dataMember.name}
          </a>
          <Countdown
            date={timeLeft}
            onComplete={completeHandler}
            renderer={rendererTime}
            daysInHours={true}
            // zeroPadTime={2}
          />
        </nav>
        <div style={{ padding: "10px" }}>
          <div className="row container-fluid mt-5">
            <div
              className="col-7 ml-3 mt-2"
              style={{ backgroundColor: "#f1f1f1" }}
            >
              <div>
                <div className="mt-2 ml-2">
                  <span>
                    <label htmlFor="tags" className="mr-1">
                      <b className="heading">Question</b>
                    </label>
                    <select
                      value={currentProblem}
                      onChange={changeProblemHandler}
                      id="problems"
                      className="form-control form-inline mb-2 mx-2 language"
                      style={{ width: "20%" }}
                    >
                      {listQuestion.map((item, index) => (
                        <option
                          key={index}
                          value={index + 1}
                        >{`${item.question_code} - ${item.data_question.question_name}`}</option>
                      ))}
                    </select>
                  </span>
                  <span>
                    <label htmlFor="tags" className="mr-1">
                      <b className="heading">Language</b>
                    </label>
                    <select
                      value={languageId}
                      onChange={changeLanguageHandler}
                      id="tags"
                      className="form-control form-inline mb-2 mx-2 language"
                      style={{ width: "20%" }}
                    >
                      {listQuestion[currentProblem - 1].language.map(
                        (item, index) => (
                          <option
                            key={index}
                            value={item.detail_language.editor_code}
                          >{`${item.detail_language.name}`}</option>
                        )
                      )}
                    </select>
                  </span>
                </div>
                <div className="row container">
                  <div className="mr-3">
                    <a
                      href={
                        listQuestion[currentProblem - 1].data_question
                          .instruction
                      }
                      target="_blank"
                    >
                      <label style={{ cursor: "pointer" }}>
                        <span className="badge badge-info heading">
                          <i className="fas fa-info-circle"></i> Intruction
                        </span>
                      </label>
                    </a>
                  </div>
                  <div className="mr-3">
                    <label
                      onClick={dialogOpenHandler}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="badge badge-info heading">
                        <i className="fas fa-user fa-fw fa-md"></i> User Input
                      </span>
                    </label>
                    <Dialog
                      open={dialogOpen}
                      onClose={dialogCloseHandler}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"User Input"}
                      </DialogTitle>
                      <DialogContent dividers>
                        <DialogContentText id="alert-dialog-description">
                          <textarea
                            style={{
                              width: "100%",
                            }}
                            id="input"
                            value={userInput}
                          ></textarea>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={dialogCloseHandler}
                          variant="outlined"
                          color="primary"
                        >
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <div className="mr-3">
                    <label
                      onClick={getResultHandler}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="badge badge-info heading">
                        <i className="fas fa-poll-h"></i> Result
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <AceEditor
                id="source"
                className=" source"
                placeholder="Source Code"
                mode={languageCode}
                theme="dracula"
                name="solution"
                onChange={changeInputHandler}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={input}
                width="100%"
                height="60vh"
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: true,
                  showLineNumbers: true,
                  tabSize: 4,
                  wrap: true,
                }}
              />

              <div className="d-flex justify-content-between mt-3 mb-3">
                <button
                  type="submit"
                  className={
                    isSubmit
                      ? "btn btn-success ml-2 mr-2"
                      : `btn btn-success ml-2 mr-2 ${classes.finishIcon} `
                  }
                  // disabled={isSubmit ? true : false}
                  onClick={dialogFinishOpen}
                >
                  <span
                    style={{
                      fontWeight: "500",
                      marginRight: "5px",
                    }}
                  >
                    Submit
                  </span>
                  <i className="fas fa-check"></i>
                </button>
                <button
                  type="submit"
                  className={
                    isSubmit
                      ? "btn btn-danger ml-2 mr-2"
                      : `btn btn-danger ml-2 mr-2 ${classes.runIcon} `
                  }
                  // disabled={isSubmit ? true : false}
                  onClick={submitHandler}
                >
                  {isSubmit ? (
                    <>
                      <span
                        style={{
                          fontWeight: "500",
                          marginRight: "5px",
                        }}
                      >
                        Loading
                      </span>{" "}
                      <i class="fas fa-cog fa-spin"></i>
                    </>
                  ) : (
                    <>
                      <span
                        style={{
                          fontWeight: "500",
                          marginRight: "5px",
                        }}
                      >
                        RUN
                      </span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  )}
                </button>
              </div>
              {/* <Dialog
                onClose={dialogFinishClose}
                aria-labelledby="customized-dialog-title"
                open={dialogFinish}
              >
                <DialogContent dividers>
                  <h4 className={classes.newHeadlineH6}>
                    Are you sure to submit test ?
                  </h4>
                  <h6 className={classes.newBody1Regular}>
                    You have 2 unsolved questions
                  </h6>
                  <hr />
                  {showFinalScore()}
                </DialogContent>
                <DialogActions
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    onClick={dialogFinishClose}
                    variant="outlined"
                    className={classes.buttonCancelFinish}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={finishHandler}
                    variant="contained"
                    className={classes.buttonFinish}
                  >
                    Agree & Finish
                  </Button>
                </DialogActions>
              </Dialog> */}

              <DialogSubmit
                dialogFinishClose={dialogFinishClose}
                dialogFinish={dialogFinish}
                finishHandler={finishHandler}
                scoreObject={scoreObject}
              />
            </div>
            <div className="col-5 mt-2">
              <div style={{ display: "block" }}>
                <textarea
                  style={{
                    width: "40%",
                    height: "85%",
                    maxHeight: "800px",
                    boxSizing: "border-box",
                    border: "1px solid #FF0000",
                    marginTop: "20px",
                    marginRight: "5px",
                    resize: "none",
                  }}
                  id="output"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default NewCompiler;
